

















import Vue from 'vue';
import MembershipBusinessResourceViewModel from '../../../../services/viewModel/resource/MembershipBusinessResourceViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    isLoading: false,
    requestError: undefined,
    businessData: undefined as MembershipBusinessResourceViewModel | undefined,
  }),
  computed: {
    business(): MembershipBusinessResourceViewModel {
      return this.businessData ?? {} as MembershipBusinessResourceViewModel;
    },
    businessId(): string {
      const { id } = this.$router.currentRoute.params;
      return id;
    },
  },
  beforeMount() {
    this.populateData();
  },
  methods: {
    async populateData() {
      this.isLoading = true;
      try {
        this.businessData = await this.$service.api.memberships.getMyBusinessDetail(this.businessId);
      } catch (error: any) {
        this.requestError = error;
      }
      this.isLoading = false;
    },
  },
});
